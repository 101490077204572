import React, { lazy, Suspense } from 'react';
import LanguageProvider from "./contexts/LanguageContext";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import './App.scss';

const Routes = lazy(() => import("./Routes"));

const App = () => {
  return (
    <LanguageProvider>
      <Suspense fallback={<div></div>}>
        <Routes />
      </Suspense>
    </LanguageProvider>
  );
}

export default App;
