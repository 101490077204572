import React, {Component, createContext} from "react";

import translate from "../content/translate.json";
import languagesJSON from "../content/languages.json";

export const LanguageContext = createContext("en");

class LanguageProvider extends Component {
    constructor(props) {
        super(props);

        let {defaultRegions, languageRegions} = languagesJSON;

        const regions = defaultRegions;
        let languages = languageRegions;


        const splitURL = window.location.pathname.split("/");
        let region = "";
        splitURL.some(r => {
            if (regions.includes(r)) region = r
        })

        if (languages[region]?.length > 2) {
            this.lang3 = languages[region] ? languages[region][2] : "kr";
        }

        if (languages[region.toLowerCase()] && languages[region.toLowerCase()]?.length === 1) {
            this.lang1 = languages[region.toLowerCase()][0];
        } else {
            this.lang1 = languages[region] ? languages[region][0] : "ar";
            this.lang2 = languages[region] ? languages[region][1] : "en";
        }


        this.translate = translate;

        let lang = this.getLang(languages[region]?.length > 2, splitURL);
        let dir = (lang === "ar" || lang === "kr") ? "rtl" : "";
        if (
            splitURL.includes(this.lang1) ||
            (splitURL.includes(this.lang2) && splitURL.indexOf(this.lang2) !== 2) ||
            (splitURL.includes(this.lang3) && splitURL.indexOf(this.lang3) !== 2)
        ) {
            let oldLang = this.getLang(languages[region]?.length > 2, splitURL);
            let newUrl = window.location.pathname.split(oldLang)
                .map(link => {
                    link = link.split("/").join("");
                    return link ? link : "";
                }).filter(Boolean);
            newUrl += oldLang === this.lang2 ? `/${this.lang2}` : "";
            window.location.href = "/" + newUrl + window.location.search;
        }

        this.html = document.querySelector("html");
        this.html.setAttribute("lang", lang);
        localStorage.setItem("lang", lang);

        this.state = {
            lang,
            translate: translate[lang],
            dir
        };

        window.lang = lang;
    }

    checkLang() {
        // const splitURL = window.location.pathname.split("/");
        // let lang = this.getLang(this.lang3, splitURL);
        // const dir = lang === "ar" || "kr" ? "rtl" : "";
        // if (this.state.lang !== lang) {
        //     const translate = this.translate[lang];
        //     this.setState({lang, translate, dir});
        // }
    }

    getLangIfThree(splitURL) {
        let lang;
        if (splitURL.includes(this.lang3)) {
            lang = this.lang3
        } else if (splitURL.includes(this.lang2)) {
            lang = this.lang2
        } else {
            lang = this.lang1
        }
        return lang
    }

    getLang(isThree, splitURL) {
        let lang;
        if (isThree) {
            lang = this.getLangIfThree(splitURL)
        } else {
            lang = !splitURL.includes(this.lang2) ? this.lang1 : this.lang2;
        }
        return lang;
    }

    toggleLang = (lang, push) => {
      if (window.lang === lang) return false;
      window.lang = lang;

      let newUrl = window.location.pathname;
      const langInUrl = languagesJSON.usedLangs.find((lang) => newUrl.includes(`/${lang}`));

      if (langInUrl && this.lang1 === lang) {
        newUrl = newUrl.replace(`/${langInUrl}`, "");
      }

      if (!langInUrl) {
        newUrl = newUrl.split("/").reduce((acc, path, n) => {
          if (path === "") return acc;
          if (languagesJSON.defaultRegions.includes(path)) return `${acc}/${path}/${lang}`;
          return `${acc}/${path}`;
        }, "");
      }

      newUrl = newUrl.replace(`/${langInUrl}`, `/${lang}`);

      if (push) {
        push(newUrl);
      }
      
      let dir = lang === "ar" || "kr" ? "rtl" : "";
      const translate = this.translate[lang];
      this.setState({lang, translate, dir});
    };

    getTranslate = (key) => {
        let {translate} = this.state;
        if (key === undefined) return "";
        if (typeof key === "boolean") return key;
        const keys = key.split(".");
        const keysLength = keys.length;
        if (keysLength < 1) return key;
        for (let index = 0; index < keysLength; index++) {
            if (!translate?.[keys[index]]) return key;
            translate = translate[keys[index]];
        }
        return translate;
    };

    render() {
        return (
            <LanguageContext.Provider value={{
                ...this.state,
                toggleLang: this.toggleLang.bind(this),
                getTranslate: this.getTranslate.bind(this),
                checkLang: this.checkLang.bind(this)
            }}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}

export default LanguageProvider;
